// 证书品牌
const brand = {
  title: '证书品牌'
}

// 证书类型
const type = {
  title: '证书类型',
  options: [
    {
      label: '域名级（DV）TLS 证书',
      link: '/ssl/domain-validation'
    },
    {
      label: '企业级（OV）TLS 证书',
      link: '/ssl/organization-validation'
    },
    {
      label: '增强级（EV）TLS 证书',
      link: '/ssl/extended-validation'
    },
    {
      label: '单域名 TLS 证书',
      link: '/ssl/single-domain'
    },
    {
      label: '通配符 TLS 证书',
      link: '/ssl/wildcard'
    },
    {
      label: '弹性 TLS 证书',
      link: '/ssl/flex-tls'
    },
    {
      label: '公网 IP 地址 TLS 证书',
      link: '/ssl/public-ip-address'
    },
    {
      label: '中国 OCSP TLS 证书',
      link: '/ssl/china-optimized-ocsp-tls'
    }
  ]
}

// 数字签名
const sign = {
  title: '数字签名',
  options: [
    {
      label: '企业级（OV）代码签名',
      link: '/digital-signing/code-signing'
    },
    {
      label: '增强级（EV）代码签名',
      link: '/digital-signing/ev-code-signing'
    },
    {
      label: '邮件签名证书（S/MIME）',
      link: '/digital-signing/email-signing'
    },
    {
      label: '邮件标记证书',
      link: '/digital-signing/verified-mark-certificates'
    },
    {
      label: '文件签名证书',
      link: '/digital-signing/document-signing'
    },
    {
      label: 'AATL 签名证书',
      link: '/digital-signing/aatl-signing-certificates'
    }
  ]
}

// 证书管理
const mnage = {
  title: '证书管理',
  options: [
    {
      label: '证书工具',
      link: '/tools/parse-cert'
    },
    {
      label: '发现证书',
      link: '/certhub/discovery'
    },
    {
      label: '证书管理',
      link: '/certhub/inventory'
    },
    {
      label: '证书分析',
      link: '/certhub/analyse'
    },
    {
      label: '证书审计',
      link: '/certhub/audit'
    },
    {
      label: '证书监控',
      link: '/certhub/monitor'
    },
    {
      label: '证书日志查询',
      link: '/certhub/log'
    }
  ]
}

// 解决方案
const solutions = {
  title: '解决方案',
  options: [
    {
      label: '等保整改',
      link: '/compliance'
    },
    {
      label: '等保评测',
      link: '/evaluation'
    },
    {
      label: '公有云',
      link: '/public-cloud'
    },
    {
      label: '飞连',
      link: '/zerotrust'
    },
    {
      label: '私有 PKI',
      link: '/private-pki'
    },
    {
      label: '可信电子签名',
      link: '/signature'
    },
    {
      label: '可信认证联盟',
      link: '/certificationalliance'
    },
    {
      label: '客户端证书',
      link: '/scalesmime'
    },
    {
      label: '云安全访问服务',
      link: '/sase'
    },
    {
      label: 'SSL应用安全网关',
      link: '/gateway'
    },
    {
      label: 'IDC 服务',
      link: '/idc'
    }
  ]
}

// 关于我们
const about = {
  title: '关于我们',
  options: [
    {
      label: '公司简介',
      link: '/about-us'
    },
    {
      label: '联系我们',
      link: '/contact-us'
    },
    // {
    //   label: '信任中心',
    //   link: '/trust-center'
    // },
    {
      label: '付款方式',
      link: '/bank-details-and-payment-methods'
    },
    // {
    //   label: '大客户计划',
    //   link: '/partner/enterprise'
    // },
    {
      label: '合作伙伴',
      link: '/partner/reseller'
    },
    // {
    //   label: '联盟推广计划',
    //   link: '/partner/affiliate'
    // },
    // {
    //   label: '会员折扣计划',
    //   link: '/partner/member'
    // },
  ]
}

export default {
  vfooter: {
    type,
    sign,
    mnage,
    about,
    brand,
    solutions
  }
}